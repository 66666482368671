import React from 'react';
import TariffCard from './TariffCard';

const tariffData = [
  {
    price: '8500₽',
    name: 'MSK-1',
    features: ['AMD Ryzen 9 3900X / 5900X 12c/24t', '64 GB ОЗУ', '500 GB NVMe SSD', 'до 1 Гбит/с', 'Powerful L3-L4 DDoS Protection', 'Локация: Москва'],
  },
  {
    price: '9500₽',
    name: 'MSK-2',
    features: ['AMD Ryzen 9 3900X / 5900X 12c/24t', '128 GB ОЗУ', '1 TB NVMe SSD', 'до 1 Гбит/с', 'Powerful L3-L4 DDoS Protection', 'Локация: Москва'],
  },
  {
    price: '10500₽',
    name: 'MSK-3',
    features: ['AMD Ryzen 9 5950X 16c/32t', '128 GB ОЗУ', '1 TB NVMe SSD', 'до 1 Гбит/с', 'Powerful L3-L4 DDoS Protection', 'Локация: Москва'],
  },
  {
    price: '12500₽',
    name: 'MSK-4',
    features: ['AMD Ryzen 9 7950X 16c/32t', '128 GB ОЗУ', '1 TB NVMe SSD', 'до 1 Гбит/с', 'Powerful L3-L4 DDoS Protection', 'Локация: Россия'],
  },
  {
    price: '7750₽',
    name: 'DE-1',
    features: ['AMD Ryzen 7 3700X 8c/16t', '64 GB ОЗУ', '2 x 512 GB SSD', 'до 1 Гбит/с', 'Basic L3-L4 DDoS Protection', 'Локация: Германия']
  },
  {
    price: '5200',
    name: 'DE-2',
    features: ['AMD Ryzen 5 3600 6c/12t', '64 GB ОЗУ', '2 x 512 GB SSD', 'до 1 Гбит/с', 'Basic L3-L4 DDoS Protection', 'Локация: Германия']
  },
  {
    price: '7515₽',
    name: 'DE-3',
    features: ['AMD Ryzen 9 3900 12c/24t', '128 GB ОЗУ', '2 x 2 GB SSD', 'до 1 Гбит/с', 'Basic L3-L4 DDoS Protection', 'Локация: Германия']
  },

];


const TariffPlans = () => {
  return (
    <section className="tariff-plans">
      <div className="tariff-grid">
        {tariffData.map((tariff, index) => (
          <TariffCard key={index} {...tariff} />
        ))}
      </div>
    </section>
  );
};

export default TariffPlans;