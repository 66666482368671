import React from "react";
import './styles/entry.css'; // подключаем ваш CSS
import './styles/index.css'
import Footer from "./components/Footer";
import Header from "./components/Header";
const App = () => {
  return (
    <body id="homepage" data-head-attrs="id">
        <div id="__nuxt">
            <div class="home">
                <div class="backdrop" data-v-ed3e7dc2></div>
                <span></span>
                <Header />
                <div class="downloads-hero" data-v-79de662d>
                    <div class="downloads-hero__image" data-v-79de662d><img src="assets/image/logo.png" data-v-79de662d />
                    </div>
                    <div class="downloads-hero__title" data-v-79de662d>ItsyCloud — Быстрый хостинг</div>
                    <div class="downloads-hero__actions" data-v-79de662d="">
                        <a href="https://my.itsycloud.me" data-v-676b978b="">Личный кабинет</a>
                    </div>
                    <span data-v-79de662d></span>
                    <div class="downloads-hero__subtitle" data-v-79de662d>Мы предоставляем сверхбыстрые VDS/VDS сервера на современных процессорах, и NVME накопителях</div>
                </div>
                <div class="downloads-platform" data-v-9d072c30>
                    <div class="downloads-platform__wrapper" data-v-9d072c30>
                       <div class="downloads-platform__col" data-v-9d072c30>
                          <div class="downloads-platform__title" data-v-9d072c30><br />Поддержка</div>
                          <span data-v-9d072c30></span>
                          <div class="downloads-platform__subtitle" data-v-9d072c30>Мы готовы помочь Вам подобрать необходимое оборудование, бесплатно перенести проекты, а также решить любую проблему на Вашем сервере абсолютно бесплатно в кратчайшие сроки! </div>
                       </div>
                       <div class="downloads-platform__col" data-v-9d072c30>
                          <div class="downloads-platform__title" data-v-9d072c30><br />Доступные цены</div>
                          <span data-v-9d072c30></span>
                          <div class="downloads-platform__subtitle" data-v-9d072c30>Мы снизили ненужные расходы и готовы предложить вам максимальную производительность и качество за минимальные деньги! У нас для большинства серверов отсутствует плата за установку, что делает хостинг более выгодным. </div>
                       </div>
                       <div class="downloads-platform__col" data-v-9d072c30>
                          <div class="downloads-platform__title" data-v-9d072c30><br />Мощное железо</div>
                          <span data-v-9d072c30></span>
                          <div class="downloads-platform__subtitle" data-v-9d072c30>Мы используем только мощные процессоры, SSD и NVMe диски. Только качественное оборудование от известных во всех мире вендоров, провернное временем, а также подходящие под любые задачи!</div>
                       </div>
                    </div>
                 </div>
                 <div 
                    className="section__outer" 
                    style={{ '--section-background': '#02081C', '--section-background-opacity': 1 }} 
                    data-v-532fff74=""
                    >
                    <div class="section__outer__background" data-v-532fff74></div>
                    <div class="section__outer__wrapper" data-v-532fff74>
                    <div 
                        className="section" 
                        style={{ 
                            '--panel-color': 'rgba(21, 27, 38, 0.6)', 
                            '--inset-color': 'rgba(225, 248, 255, 0.09)' 
                        }}
                        >
                          <div class="section--split odd">
                             <div class="section--split--column documentation">
                                <div class="section--split--column--title documentation"> Панель управления </div>
                                <div class="section--split--column--text documentation"><b>Собственная панель управления. Простая и понятня.</b> Так же у нас есть API для панели, для получения обратитесь в тикет. </div>
                                <div class="section--split--column--image documentation"><img src="assets/image/diagram.png" alt="loading" /></div>
                             </div>
                             <div class="section--split--column share">
                                <div class="section--split--column--title share">Сеть</div>
                                <div class="section--split--column--text share"><b>Высокоскоростной канал на каждом сервере</b>, низкий пинг, и множество локаций. </div>
                                <div class="section--split--column--image share"><img src="assets/image/share-globe.png" alt="loading" /></div>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <Footer />
            </div>
        </div>
        <script type="module" src="/assets/js/entry.js" crossorigin></script>
        <script type="module" src="/assets/js/downloads.js" crossorigin></script>
    </body>
  )
}

export default App;
