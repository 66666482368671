import React from 'react';
import FAQItem from './FAQItem';

const faqData = [
  {
    question: 'Как подключиться к моему выделенному серверу?',
    answer: 'Для подключения к вашему серверу через SSH можно использовать такие утилиты, как Bitvise SSH Client, Putty и другие. После скачивания ознакомьтесь с документацией по клиенту и подключитесь к серверу, используя данные, полученные в письме активации после оплаты сервера.',
  },
  {
    question: 'В чем разница между выделенным сервером и VPS/VDS?',
    answer: 'Выделенный сервер предоставляет вам полное, физическое оборудование, тогда как VPS/VDS — это виртуальный сервер, находящийся на одном физическом сервере с другими пользователями. Выделенный сервер предлагает более высокую производительность и полное управление оборудованием.',
  },
  {
    question: 'Кто может получить доступ к моей информации на выделенном сервере?',
    answer: 'Только лица, которым вы предоставляете доступ самостоятельно. Однако в случае поступления жалобы наши технические специалисты могут проверить информацию на вашем сервере для соблюдения правил использования.',
  },
];


const FAQ = () => {
  return (
    <section className="faq-section">
      <h2 className="section-title">FaQ</h2>
      <div className="faq-list">
        {faqData.map((item, index) => (
          <FAQItem key={index} {...item} />
        ))}
      </div>
    </section>
  );
};

export default FAQ;