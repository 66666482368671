import React from 'react';

// Константы с навигационными ссылками
const navigationLinks = [
  { href: "vps", label: "Виртуальные серверы" },
  { href: "dedic", label: "Выделенные серверы" },
  { href: "offer.pdf", label: "Публичная оферта" },
  { href: "https://my.itsycloud.me", label: "Личный кабинет", className: "header--content--log-in" },
];

const Header = () => {
  return (
    <div className="header" data-v-5f739898>
      <div className="header--content" data-v-5f739898>
        <div className="header--content--logo" data-v-5f739898>
          <a href="/" className="" data-v-5f739898>
            ItsyCloud
          </a>
        </div>
        <div className="header--content--navigation" data-v-5f739898>
          {navigationLinks.map((link, index) => (
            <a 
              key={index} 
              href={link.href} 
              className={link.className || ""} 
              data-v-5f739898
            >
              {link.label}
            </a>
          ))}
          <div className="header--content--divider" data-v-5f739898></div>
        </div>
        <div className="header--content--mobile-navigation" data-v-5f739898>
          <button data-v-5f739898>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon" viewBox="0 0 20 20" fill="currentColor" data-v-5f739898>
              <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" data-v-5f739898></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
