import React from 'react';
import FAQItem from './FAQItem';

const faqData = [
  {
    question: 'Как подключиться к моему серверу?',
    answer: 'Для подключения к SSH, вы можете использовать множество утилит, например: Bitvise SSH Client, Putty и так далее... После скачивания - ознакомьтесь с документацией по клиенту и подключитесь к серверу по данным, которые получили в письме активации после заказа и оплаты сервера.',
  },
  {
    question: 'Чем отличается VDS от VPS?',
    answer: 'Разницы нет. VDS (Virtual Dedicated Server) в переводе с английского означает виртуальный выделенный сервер. VPS (Virtual Private Server) — виртуальный частный сервер. Два разных названия одной и той же технологии - виртуального сервера.',
  },
  {
    question: 'Кто может получить доступ к моей информации?',
    answer: 'Только лица, которым Вы даете доступ самостоятельно. Однако в случае поступления жалобы наши технические специалисты могут проверить информацию на Вашем сервере для проверки.',
  },
];

const FAQ = () => {
  return (
    <section className="faq-section">
      <h2 className="section-title">FaQ</h2>
      <div className="faq-list">
        {faqData.map((item, index) => (
          <FAQItem key={index} {...item} />
        ))}
      </div>
    </section>
  );
};

export default FAQ;