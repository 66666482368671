import React from 'react';

const TariffCard = ({ price, name, features }) => {
  const handleButtonClick = () => {
    if (name === 'DE-Custom') {
      window.location.href = 'https://t.me/itsycloud_support';
    } else {
      window.location.href = 'https://my.itsycloud.me';
    }
  };

  return (
    <div className="tariff-card">
      <div className="tariff-price">
        {price} / <span className="period">МЕСЯЦ</span> *
      </div>
      <h3 className="tariff-name">{name}</h3>
      <ul className="feature-list">
        {features.map((feature, index) => (
          <li key={index} className="feature-item">{feature}</li>
        ))}
      </ul>
      <button className="order-button" onClick={handleButtonClick}>
        {name === 'DE-Custom' ? 'Перейти к созданию' : 'Заказать сервер'}
      </button>
    </div>
  );
};

export default TariffCard;
