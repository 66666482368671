import React from "react";
import './styles/entry.css'; // подключаем ваш CSS
import './styles/index.css'
import './styles/global.css'
import Footer from "./components/Footer";
import Header from "./components/Header";
import TariffPlans from "./components/export/TariffPlans";
import FAQ from "./components/export/FAQ";
const App = () => {
  return (
    <body id="homepage" data-head-attrs="id">
        <div id="__nuxt">
            <div class="home">
                <div class="backdrop" data-v-ed3e7dc2></div>
                <span></span>
                <Header />
                <div class="hero" data-v-644f7918="">
                    <h2 data-v-644f7918="">Тарифные планы</h2>
                    <h3 data-v-644f7918=""> Мы предоставляем сверхбыстрые VPS/VDS сервера. Виртуализация KVM. Множество локаций в Европе. Бесплатная установка и Поддержка. </h3>
                </div>
                <div class="frequency" data-v-644f7918="">
                    <div class="c-icon-switch" data-v-644f7918="" data-v-842ebd99="">

                    </div>
                </div>
                <div class="features" data-v-644f7918="">
                    <TariffPlans />
                    </div>                    
                    <FAQ />
                     <Footer />
            </div>
        </div>
        <script type="module" src="/assets/js/entry.js" crossorigin></script>
        <script type="module" src="/assets/js/pricing.js" crossorigin></script>
    </body>
)
}

export default App;
