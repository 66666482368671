import React from 'react';

// Константы с ссылками
const footerLinks = [
  { href: "https://t.me/itsycloud", label: "Телеграм" },
  { href: "policy.pdf", label: "Политика конфиденциальности" },
  { href: "offer.pdf", label: "Публичная оферта"},
  { href: "user-agreement.pdf", label: "Пользовательское соглашение"},
  { href: "https://my.itsycloud.me", label: "Личный кабинет" }
];

const Footer = () => {
  return (
    <div className="footer" data-v-cb14babb>
      <div className="footer--content" data-v-cb14babb>
        <div className="footer--content--logo" data-v-cb14babb>
          <a href="/" className="" data-v-cb14babb>
            ItsyCloud.me
          </a>
        </div>
        <div className="footer--content--links" data-v-cb14babb>
          <div className="footer--content--links--col" data-v-cb14babb>
            {footerLinks.slice(0, 3).map((link, index) => (
              <a key={index} href={link.href} data-v-cb14babb>
                {link.label}
              </a>
            ))}
          </div>
          <div className="footer--content--links--col" data-v-cb14babb>
            {footerLinks.slice(3).map((link, index) => (
              <a key={index} href={link.href} className="" data-v-cb14babb>
                {link.label}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
