import React from 'react';
import TariffCard from './TariffCard';

const tariffData = [
  {
    price: '170₽',
    name: 'DE-1',
    features: ['1 vCore', '2 GB ОЗУ', '20 GB Диска', '250 Мбит/с', 'Powerful L3-L4 DDoS Protection'],
  },
  {
    price: '300₽',
    name: 'DE-2',
    features: ['2 vCore', '4 GB ОЗУ', '40 GB Диска', '250 Мбит/с', 'Powerful L3-L4 DDoS Protection'],
  },
  {
    price: '560₽',
    name: 'DE-3',
    features: ['4 vCore', '8 GB ОЗУ', '80 GB Диска', '250 Мбит/с', 'Powerful L3-L4 DDoS Protection'],
  },
  {
    price: '880₽',
    name: 'DE-4',
    features: ['4 vCore', '16 GB ОЗУ', '160 GB Диска', '250 Мбит/с', 'Powerful L3-L4 DDoS Protection'],
  },
  {
    price: '1720₽',
    name: 'DE-5',
    features: ['8 vCore', '32 GB ОЗУ', '320 GB Диска', '250 Мбит/с', 'Powerful L3-L4 DDoS Protection'],
  },
  {
    price: 'Custom',
    name: 'DE-Custom',
    features: ['Кастомное количество ядер', 'Кастомный объем ОЗУ', 'Кастомный объем диска', '500 Мбит/с', 'Powerful L3-L4 DDoS Protection'],
  },
];

const TariffPlans = () => {
  return (
    <section className="tariff-plans">
      <div className="tariff-grid">
        {tariffData.map((tariff, index) => (
          <TariffCard key={index} {...tariff} />
        ))}
      </div>
    </section>
  );
};

export default TariffPlans;